import React from 'react'
import { useState } from "react";
import axios from 'axios';
import { jsPDF } from 'jspdf';
import GetRequest from '../Services/APIRequest'
import AccountLedgerRecord from './AccountLedgerRecord'
import CreditBalanceBoosterBox from '../IndicatorBox/CreditBalanceBoosterBox'
import CreditBalanceParisBox from '../IndicatorBox/CreditBalanceParisBox'
import TotalCreditAmountAddFYBox from '../IndicatorBox/TotalCreditAmountAddFYBox'
import TotalBilledAmountFYBox from '../IndicatorBox/TotalBilledAmountFYBox'
import TotalInterestOnOutstandingAmountBox from '../IndicatorBox/TotalInterestOnOutstandingAmountBox'
import TotalOutStandingAmountBox from '../IndicatorBox/TotalOutStandingAmountBox'
import LastPaymentMadeDetailBox from '../IndicatorBox/LastPaymentMadeDetailBox'
import AccountLedgerList from './AccountLedgerList'
import TotalReceiptAmounts from '../IndicatorBox/TotalReceiptAmounts'

import { Link } from 'react-router-dom'


function AccountDashboard() {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [pdfdata, setPdfData] = useState(null);
    const [compId, setCompId] = useState(localStorage.getItem('companyIdBooster'));

    const setBoosterCompanyId = () => {
        setCompId(localStorage.getItem('companyIdBooster'));
        document.getElementById('parisBtnColor').classList.remove('active')
        document.getElementById('boostBtnColor').classList.add('active')
    }
    const setParisCompanyId = () => {
        setCompId(localStorage.getItem('companyIdParis'));
        document.getElementById('parisBtnColor').classList.add('active')
        document.getElementById('boostBtnColor').classList.remove('active')
    }

    const formatCurrency = (amount) =>
        new Intl.NumberFormat('en-IN', {
            // style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);

    const downloadLedger = (event) => {
        event.preventDefault();
        axios.get(`https://dpapi.boosterparis.in/api/Account/DownloadLedgerFile/${distID}/${compId}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'transactiondetails.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
    }
    const generatePdf = () => {
        const doc = new jsPDF();
        doc.text(`Account Ledger - ${distID}`, 14, 12);
        doc.text(pdfdata, 10, 20);
        doc.save(`AccountLedger-${distID}.pdf`);
    };

    return (
        <>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="row card-body">
                                    <div className="row">
                                        <CreditBalanceBoosterBox />
                                        <CreditBalanceParisBox />
                                        {/* <TotalCreditAmountAddFYBox /> */}
                                        <TotalBilledAmountFYBox />
                                        <TotalReceiptAmounts />
                                        {/* <TotalInterestOnOutstandingAmountBox /> */}
                                        {/* <TotalOutStandingAmountBox /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 compSwitchBTnBg">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <button type="button" id="boostBtnColor" className="btn  btn-info btn-my btn-block active" onClick={setBoosterCompanyId}> Booster</button>
                                            <button type="button" id="parisBtnColor" className="btn  btn-info btn-my btn-block" onClick={setParisCompanyId}>Paris </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AccountLedgerList compid={compId} />

                    </div>
                    <div className="row">
                        <div className="col-md-12 accFooBtn">
                            <a href={` https://dpapi.boosterparis.in/api/Account/DownloadLedgerFileV2/${distID}/${compId}`}
                                target="_blank">
                                <button type="button" className="btn btn-primary btn-block100"><i className="fa fa-download"></i> Download Ledger</button>  </a>
                            <Link to="/CreateQuery" className='btn btn-danger btn-block100'><i className="fa fa-plus"></i>  Raise Query</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AccountDashboard